
import { ref } from 'vue';
import { useStore } from 'vuex';
import {ElMessage, ElMessageBox} from 'element-plus';
import {clearOperateLogs, deleteOperateLogs, exportOperateLogs, getOperateLogs} from "@/api/operateLog";
import {download} from "@/api/download";
import fileDownload from "js-file-download";
export default {
  setup(){
    const store = useStore();

    const exporting = ref(false);

    const formSize = ref('large');

    //适配移动端
    if(store.state.app.isMobile){
      formSize.value = 'mini';
    }else{
      formSize.value = 'large';
    }

    const statusDict = [
      {
        status: 1,
        label: '成功',
      },
      {
        status: 0,
        label: '失败',
      }
    ];

    let resetSearch = false;

    const table:any = ref({
      page: 1,        // 当前在第几页
      pageSize: 30,   // 一页显示多少
      total: 0,       // 数据总量
      loading: true,  // 加载中
      data: [],       // 表格数据
      isMobile: false,// 表格是否移动端
    });

    const canBeDelete = ref(false);

    const visible = ref(false);

    const searchText = ref("");

    const searchParams = ref(
        {
          moduleName: '',
          username: '',
          status: '',
        }
    );

    table.value.isMobile = store.state.app.isMobile;

    const listLogs = () => {
      getOperateLogs({page: table.value.page,pageSize: table.value.pageSize}).then((res: any) => {
        table.value.total = res.data.total;
        table.value.data = res.data.list;
        table.value.loading = false;
      });
    };

    listLogs();

    const handleSizeChange = (pageSize) => {
      table.value.loading = true;
      table.value.pageSize = pageSize;
      listLogs();
    }

    const handleCurrentChange = (page) => {
      table.value.loading = true;
      table.value.page = page;
      listLogs();
    }

    const searchOperateLogs = () => {
      table.value.loading = true,
          getOperateLogs({
            page: table.value.page,
            pageSize: table.value.pageSize,
            moduleName: searchParams.value.moduleName,
            username: searchParams.value.username,
            status: searchParams.value.status,

          }).then((res: any) => {
            table.value.data = res.data.list;
            table.value.total = res.data.total;
            table.value.loading = false;
          });
    }

    const getStatusDict = (status) => {
      let label = '';
      let type = '';
      if(status == "0"){
        label = '失败';
        type = 'warning';
      }else{
        label = '成功';
        type = 'success';
      }
      return {"label": label,"type": type};
    };

    const form = ref();


    const handleDetailClick = (row: any) => {
        visible.value = true;
        form.value = row;
    }

    const ids = ref();

    const handleSelectionChange = (selection: any) => {
      ids.value = selection.map(item => item.id) + "";
      canBeDelete.value = selection.length;
    }

    const handleDelete = () => {
      ElMessageBox.confirm('是否删除选中日志？', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        deleteOperateLogs(ids.value).then((res: any) => {
          if(!res.errCode) {
            ElMessage.success("删除成功！");
            listLogs();
          }else {
            ElMessage.error(res.detail);
          }
        });
      });

    }

    const handleClear = () => {
      ElMessageBox.confirm('是否清空所有操作日志？', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        clearOperateLogs().then((res: any) => {
          if(!res.errCode) {
            ElMessage.success("清空日志成功！");
            listLogs();
          }else {
            ElMessage.error(res.detail);
          }
        });
      });
    };

    const handleExport = () => {
      ElMessageBox.confirm('是否导出？', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        exporting.value = true;
        exportOperateLogs().then((res: any) => {
          if(res.data) {
            const path = res.data;
            download(path).then((blob: any) => {
              fileDownload(blob,new Date().getTime() + "_操作日志.xlsx");
              exporting.value = false;
            }).catch(() => {
              exporting.value = false;
            });
          }
        }).catch(() => {
          exporting.value = false;
        });
      });
    };


    return {
      table,
      handleSizeChange,
      handleCurrentChange,
      visible,
      form,
      listLogs,
      handleDetailClick,
      searchOperateLogs,
      searchText,
      getStatusDict,
      handleSelectionChange,
      ids,
      handleDelete,
      handleClear,
      canBeDelete,
      searchParams,
      statusDict,
      formSize,
      handleExport,
      exporting,

    }
  }
}
